<template>
  <div class="web_grop_intr">
    <img
      src="@/assets/img/index/Rectangle 225@2x.png"
      alt=""
      class="lop_bg"
      @click="clickLoop"
    />
    <img
      src="@/assets/img/index/loop@2x.png"
      alt=""
      class="loop"
      @click="clickLoop"
    />
    <div v-if="showVideo" class="video_bg">
      <img
        src="@/assets/img/index/Close _ 24 _ Outline_white@2x.png"
        alt=""
        class="close_video"
        @click="closeScreenFull"
      />
      <video
        :src="
          'https://assets-video.myazstore.com/azg-website/2023_07_19.mp4?' +
            new Date().getTime()
        "
        class="video"
        loop
        autoplay
        controls="controls"
      ></video>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showVideo: false
    };
  },
  mounted() {
    let self = this;
    this.$nextTick(function() {
      document.addEventListener("keyup", function(e) {
        //此处填写你的业务逻辑即可
        if (e.keyCode == 27) {
          self.closeScreenFull();
        }
      });
    });
  },
  methods: {
    clickLoop() {
      this.showVideo = true;
    },
    closeScreenFull() {
      this.showVideo = false;
    }
  }
};
</script>

<style lang="less" scoped>
.web_grop_intr {
  height: calc(100vw * 40 / 192);
  position: relative;
  .lop_bg {
    width: 100%;
    // height: calc(100vw * 40 / 192);
    background: url("../../../assets/img/index/Rectangle 225@2x.png");
    transition: all 0.6s;
    // height: calc(100vw * 40 / 192);
    display: block;
    cursor: pointer;
  }
  .lop_bg:hover {
    transform: scale(1.1);
  }
  .loop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw * 6 / 192);
    height: calc(100vw * 3 / 192);
    z-index: 99;
    cursor: pointer;
  }
}
.video_bg {
  z-index: 9999;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  // width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .close_video {
    z-index: 1000;
    position: absolute;
    width: calc(100vw * 2.4 / 192);
    height: calc(100vw * 2.4 / 192);
    top: calc(100vw * 2 / 192);
    right: calc(100vw * 2 / 192);
    cursor: pointer;
  }
  .video {
    width: 100%;
    height: 100%;
  }
}
</style>
